import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import agentService from "../../services/agentService";
import { useSnackbar } from "../../context/SnackBarProvider";
import useConfirmDialog from "../../hook/useConfirm";
import { useSelector } from "react-redux";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

function AgentDetail({ isOpen, onClose, onSubmit, agent }) {

    const initialValues = {
        email: '',
        keywords: '',
        location: '',
        name: '',
        password: '',
        security: false,
        site: '',
        status: '',
        context: '',
    };

    const showAlert = useSnackbar();
    const { confirmDialog, ConfirmationDialog } = useConfirmDialog();

    const { id } = useSelector(state => state.w);
    const [formValues, setFormValues] = useState(initialValues);

    const handleDelete = async () => {
        const confirm = await confirmDialog('Voulez-vous vraiment supprimer cet élément ?');
        if (confirm) {
            try {
                // const response = await agentService.delete(agent.id);
                onSubmit();
                showAlert('Succès', 'info');
                onClose();

            } catch (error) {
                showAlert('Erreur !!!', 'error')
            }
        }
    };

    const handleSave = async () => {
        try {
            let response;
            if (agent) {
                // Update existing agent
                response = await agentService.update(agent.id, formValues);
            } else {
                // Create new agent
                const newAgent = {
                    ...formValues,
                    status: 'inactive',
                    workspace_id: id
                }
                response = await agentService.create(newAgent);
            }
            if (response) {
                showAlert('Succès', 'success');
                onSubmit();
                onClose();
            }
            setFormValues(initialValues)
        } catch (error) {
            showAlert('Erreur !!!', 'error')
        }
    }

    useEffect(() => {
        if (agent) {
            setFormValues({
                email: agent.email || '',
                keywords: agent.keywords || '',
                location: agent.location || '',
                name: agent.name || '',
                password: agent.password || '',
                security: agent.security || false,
                site: agent.site || '',
                status: agent.status || '',
                context: agent.context || 'offer',
            });
        } else {
            setFormValues(initialValues);
        }

    }, [agent])

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle className='text-primary-bleu'>Detail d'un agent</DialogTitle>

            <DialogContent>
                <form className='p-2'>
                    <div className="mb-3">
                        <TextField
                            id="outlined-name"
                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                            color="secondary"
                            label="Nom de l'agent"
                            fullWidth
                            required
                        />
                    </div>

                    {/* <div className="mb-3">
                        <TextField
                            id="outlined-site"
                            name="site"
                            value={formValues.site}
                            onChange={handleChange}
                            color="secondary"
                            label="Site"
                            fullWidth
                            required
                        />
                    </div> */}

                    <div className="mb-3">
                        <FormLabel>Site</FormLabel>
                        <RadioGroup
                            name="site"
                            value={formValues.site}
                            onChange={handleChange}
                            className="flex"
                            row
                        >
                            <FormControlLabel value="www.linkedin.com" control={<Radio />} label="Linkedin" />
                            <FormControlLabel value="www.indeed.com" control={<Radio />} label="Indeed" />
                        </RadioGroup>
                    </div>

                    <div className="mb-3">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Contexte</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                value={formValues.context}
                                label="Context"
                                name="context"
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value={'offer'}>Offre</MenuItem>
                                <MenuItem value={'company'}>Organisation</MenuItem>
                                <MenuItem value={'personna'}>Contact</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <hr className="m-2" />

                    <div className="mb-3">
                        <TextField
                            id="outlined-keywords"
                            name="keywords"
                            value={formValues.keywords}
                            onChange={handleChange}
                            color="secondary"
                            label="Mots clés"
                            slotProps={{
                                input: {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <SearchRoundedIcon />
                                        </InputAdornment>,
                                },
                            }}
                            fullWidth
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <TextField
                            id="outlined-location"
                            name="location"
                            value={formValues.location}
                            onChange={handleChange}
                            color="secondary"
                            label="Localisation"
                            slotProps={{
                                input: {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <LocationOnRoundedIcon />
                                        </InputAdornment>,
                                },
                            }}
                            fullWidth
                            required
                        />
                    </div>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={formValues.security}
                                onChange={handleChange}
                                name="security"
                                color='secondary'
                            />
                        }
                        label="Auhtentification"
                    />

                    <div className={`${!formValues.security ? 'hidden' : 'block'}`}>
                        <div className="my-2 flex justify-between gap-2">
                            <TextField
                                id="outlined-email"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                                color="secondary"
                                label="Email"
                                placeholder="Entrer l'email pour se connecter"
                                fullWidth
                                required
                            />

                            <TextField
                                id="outlined-password"
                                name="password"
                                type="password"
                                value={formValues.password}
                                onChange={handleChange}
                                color="secondary"
                                label="Mot de passe"
                                placeholder="Enter le mot de passe"
                                fullWidth
                                required
                            />
                        </div>
                    </div>

                </form>
            </DialogContent>

            <DialogActions>
                <div className='flex justify-between gap-3'>
                    <div>
                        <Button onClick={onClose}>Fermer</Button>
                    </div>
                    {agent && (
                        <div>
                            <Button onClick={handleDelete} variant='contained' color="error">Supprimer</Button>
                        </div>
                    )}
                    <div>
                        <Button onClick={handleSave} variant='contained'>Enregistrer</Button>
                    </div>
                </div>
                <ConfirmationDialog />
            </DialogActions>
        </Dialog>
    );
};

export default AgentDetail;