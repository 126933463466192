import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ShopRoundedIcon from '@mui/icons-material/ShopRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import { useState } from "react";
import { useEffect } from "react";
import companyService from "../../services/companyService";
import FormOrganisation from './FormOrganization';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Button, CircularProgress } from '@mui/material';

function ListOrganization() {

    const [companies, setCompanies] = useState([]);
    const [detailOpen, setDetailOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSelect = (company) => {
        //console.log(company);

        setSelected(company);
        setDetailOpen(true)
    };

    const handleCloseDetail = () => {
        setDetailOpen(false);
        setSelected(null);
    };

    const handleClickAdd = () => {
        setSelected(null);
        setDetailOpen(true);
    };

    const fetchCompanies = async () => {
        try {
            setLoading(true);
            const response = await companyService.getAll();
            if (response.data) {
                setCompanies(response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error)
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchCompanies();
    }, [])

    return (
        <div className="p-2">
            <div className="flex items-center justify-between">
                <h1 className="m-2 text-2xl font-bold text-primary-bleu">Liste des organisations</h1>
                <div className=''>
                    <Button onClick={handleClickAdd} variant='contained' color='secondary' startIcon={<AddCircleOutlineRoundedIcon />}>
                        Ajouter une organisation
                    </Button>
                </div>
            </div>
            {loading ? (
                <div className='m-4 w-full flex justify-center'>
                    <CircularProgress color="secondary" />
                </div>
            ) : companies.length === 0 ? (
                <p className='text-xl text-secondary-raven m-4'>Aucun élément à afficher.</p>
            ) : (
                <div className="grid gap-2 sm:gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 my-4 p-2">
                    {companies.map((org) => (
                        <div key={org.id} onClick={() => handleSelect(org)} className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-200 cursor-pointer hover:shadow-md">
                            <div className="p-4">
                                <div className="flex justify-between items-start mb-2">
                                    <h2 className="text-lg font-semibold text-primary-bleu truncate">{org.name}</h2>
                                    <span className="inline-block bg-primary-jaune text-primary-bleu px-2 py-1 rounded-full text-sm font-semibold text-nowrap">
                                        {org.sector}
                                    </span>
                                </div>
                                <p className="text-sm text-gray-600 mb-3 line-clamp-2">
                                    {org.overview ? `${org.overview.substring(0, 100)}...` : 'No profile available'}
                                </p>
                                <div className="grid grid-cols-2 gap-2 text-xs">
                                    <div className="flex items-center">
                                        <LanguageRoundedIcon className="w-4 h-4 mr-1 text-primary-bleu" />
                                        <a href={org.website} target="_blank" rel="noopener noreferrer" className="text-primary-jaune hover:underline truncate">
                                            {org.website.replace(/^https?:\/\//, '') || '-'}
                                        </a>
                                    </div>
                                    <div className="flex items-center">
                                        <LocalPhoneRoundedIcon className="w-4 h-4 mr-1 text-primary-bleu" />
                                        <span className="truncate">{org.phone_number}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <PeopleAltRoundedIcon className="w-4 h-4 mr-1 text-primary-bleu" />
                                        <span className="truncate">{org.size}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <FmdGoodRoundedIcon className="w-4 h-4 mr-1 text-primary-bleu" />
                                        <span className="truncate">{org.head_office}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <CalendarMonthRoundedIcon className="w-4 h-4 mr-1 text-primary-bleu" />
                                        <span className="truncate">Founded {org.founded_in}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <ShopRoundedIcon className="w-4 h-4 mr-1 text-primary-bleu" />
                                        <span className="truncate">{org.specializations}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )
            }
            <FormOrganisation isOpen={detailOpen} onClose={handleCloseDetail} company={selected} onSubmit={fetchCompanies} />
        </div>
    );
};

export default ListOrganization;