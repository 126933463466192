import HBarHome from "../components/HBarHome";
import PerformanceChart from '../components/PerformanceChart';
import EfficiencyCard from "../components/EfficiencyCard";
import NotificationCard from "../components/NotificationCard";
import Agentcard from "../components/AgentCard";

function HomePage() {

    return (
        <div className="h-screen overflow-hidden bg-secondary-background">
            <div className="p-2">
                <HBarHome />
                <div className="flex items-center space-x-4 mt-4" >
                    <EfficiencyCard />
                    <Agentcard />
                    <NotificationCard />


                </div>
                <PerformanceChart />

            </div>
        </div>
    );
}

export default HomePage;