import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { markAsRead, setNotifications, setW } from "../redux/wSlice";
import { Avatar, Backdrop, Badge, CircularProgress, Menu, MenuItem } from "@mui/material";
import socket from "../services/socketService";
import offerService from "../services/offerService";
import notificationService from "../services/notificationService";
import { useNavigate } from "react-router-dom";

function TopBar() {

    const [workspaceName, setWorkspaceName] = useState('');
    const { name, members, owner, id, notifications } = useSelector(state => state.w);
    const { userInfo } = useSelector(state => state.auth);
    const [anchorEl, setAnchorEl] = useState(null);
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenNotification = async (notification) => {
        console.log('Link:', notification.link);
        try {
            const response = await notificationService.read(notification.id);
            if (response.data) {
                dispatch(markAsRead(notification.id));

                if (notification.level === 'offre') {
                    navigate('donnees')
                }
                handleClose();
            }
        } catch (error) {
            console.log(error)
        }
    }


    const handleUpdate = () => {
        const newData = {
            id,
            members,
            owner,
            name: workspaceName
        };
        console.log(newData);
        dispatch(setW(newData));
    };

    const editName = (e) => {
        setWorkspaceName(e.target.value);
    };

    const sendNews = async (data) => {
        try {
            const response = await offerService.sendNews(data)
            if (response) {
                console.log("Email sent succesfully !!!")
            }
        } catch (error) {
            console.log(error)
        }
    };

    const createNofication = async (data) => {
        try {
            const response = await notificationService.create(data);
            if (response) {
                console.log('Notification created successfully', response.data.notification)
                let news = [...notifications, response.data.notification];
                dispatch(setNotifications(news))
            }
        } catch (error) {
            console.error('error:', error)
        }
    }

    useEffect(() => {
        if (name) {
            setWorkspaceName(name);
        }
    }, [name]);


    useEffect(() => {
        const handleNews = async (data) => {
            if (data) {
                console.log("New offer :", data);
                const notif = {
                    level: 'offre',
                    title: data.title,
                    description: data.description,
                    link: data.job_link,
                    status: 'unread',
                    user_id: owner.id,
                }
                console.log(notif);

                await createNofication(notif);

                const emailData = {
                    recipient: owner.email,
                    subject: "Nouvelle offre ITady",
                    title: data.title,
                    description: data.description
                }

                await sendNews(emailData);
            }
        };
        socket.on("new_offer", handleNews);

        return () => {
            socket.off("new_offer", handleNews)
        }
    }, [owner, dispatch, createNofication]);

    useEffect(() => {
        console.log('!!!! notif !!!', notifications);
        if (notifications) {
            setCount(notifications.length);
        }
    }, [notifications]);

    return (
        <header className="p-3 m-1 rounded-md shadow-sm bg-white flex justify-between items-center border border-gray-200">

            {(!name || !userInfo) && (
                <Backdrop open={true}>
                    <CircularProgress color="secondary" />
                </Backdrop>
            )}

            <div className="text-2xl text-primary-bleu font-extrabold">
                <input
                    onBlur={handleUpdate}
                    type="text"
                    className="outline-none p-1 rounded focus:ring-2 focus:ring-primary-jaune hover:ring-1 hover:ring-gray-200"
                    value={workspaceName}
                    onChange={editName}
                />
            </div>
            <div className="flex items-center">
                <div className="flex">
                    <span
                        aria-controls={open ? 'lengthbasic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        className="mx-2 hover:cursor-pointer">
                        <Badge badgeContent={count} color="secondary">
                            <NotificationsRoundedIcon className="text-primary-bleu" />
                        </Badge>
                    </span>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {
                            notifications.map((notification, index) => (
                                <MenuItem onClick={() => handleOpenNotification(notification)} key={index}>
                                    <div className="ml-3 flex-1">
                                        <p className="text-sm font-medium text-primary-bleu space-x-1">
                                            <span>
                                                {notification.title}
                                            </span>
                                            <span className={`text-xs font-semibold px-2 py-1 rounded-full bg-primary-jaune`}>
                                                {notification.level}
                                            </span></p>
                                        <p className="mt-1 text-sm text-gray-500">
                                            {notification.description.substring(0, 50)}...
                                        </p>
                                    </div>
                                </MenuItem>
                            ))
                        }
                    </Menu>
                </div>
                <div className="p-[1px] bg-gray-400 mx-2 h-5">

                </div>
                <div className="flex items-center gap-1 text-primary-bleu cursor-pointer">
                    <span>
                        <Avatar className="uppercase">
                            <PersonRoundedIcon />
                        </Avatar>
                    </span>
                    <span className="font-bold">{userInfo.firstname} {userInfo.lastname}</span>
                </div>
            </div>
        </header>
    );
};

export default TopBar;