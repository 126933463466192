import { Avatar, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const initialValue = {
    id: '',
    name: '',
    title: '',
    location: '',
    links: '',
    competence: '',
    resume: '',
}

function DetailPersonna({ isOpen, onClose, personna }) {

    const [data, setData] = useState(null)

    useEffect(() => {
        if (personna) {
            setData(personna)
        }
    }, [personna])

    const getCompetences = (comp) => {
        return comp.split('\n').map(item => item.replace('* ', ''))
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle>
                {
                    data && <div className="flex justify-between">
                        <div className="flex items-center gap-4">
                            {/* <div className="w-20 h-20 bg-gray-300 rounded-full flex items-center justify-center text-2xl font-bold text-white">
                                {data.name.split(' ').map(n => n[0]).join('')}
                            </div> */}
                            <Avatar sx={{ width: 60, height: 60 }}>
                                {data.name.split(' ').map(n => n[0]).join('')}
                            </Avatar>
                            <div>
                                <h2 className="text-2xl font-bold text-gray-800">{data.name}</h2>
                                <p className="text-sm text-gray-600 mt-1">{data.title}</p>
                            </div>
                        </div>
                        <div>
                            <Button onClick={onClose}>Fermer</Button>
                        </div>
                    </div>
                }
            </DialogTitle>
            <DialogContent>
                {
                    data && <div className="bg-white rounded-lg overflow-hidden">
                        <div>
                            <div className="">
                                <div className="flex items-center gap-2 mb-2">
                                    <LocationOnRoundedIcon />
                                    <span className="text-sm text-gray-600">{data.location}</span>
                                </div>
                                <div className="flex items-center gap-2 mb-4">
                                    <LinkedInIcon />
                                    <a href={`https://${data.links}`} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-500 hover:underline">
                                        {data.links}
                                    </a>
                                </div>
                            </div>

                            <div className="mt-6">
                                <h3 className="text-lg font-semibold text-gray-800 mb-2">Compétences</h3>
                                <div className="flex flex-wrap gap-2">
                                    {getCompetences(data.competence).map((competence, index) => (
                                        <span key={index} className="px-3 py-1 bg-gray-200 text-gray-700 rounded-full text-sm">
                                            {competence}
                                        </span>
                                    ))}
                                </div>
                            </div>

                            <div className="mt-6">
                                <h3 className="text-lg font-semibold text-gray-800 mb-2">Résumé</h3>
                                <p className="text-sm text-gray-600">{data.resume}</p>
                            </div>
                        </div>
                    </div>
                }
            </DialogContent>
        </Dialog>
    )
}
export default DetailPersonna