import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { NavLink } from "react-router-dom";
import { Tooltip } from '@mui/material';

function MyNavLink({ text, path, Icon , end=true}) {

    const activeStyle = 'bg-secondary-white p-2 rounded-lg font-bold text-primary-bleu';
    const titleElement = <div className='text-base'>{text}</div>;
    return (
        <NavLink to={path} className={({ isActive }) => isActive ? activeStyle : 'text-secondary-white'} end={end}>
            <Tooltip title={titleElement} placement='right'>
                {Icon && <Icon />}
            </Tooltip>
        </NavLink>
    );

}

function SideBar() {

    return (
        <div className="h-screen flex flex-col p-3 py-5 bg-primary-bleu items-center justify-between">
            <div className="flex flex-col items-center gap-4">
                <MyNavLink text="Accueil" path={``} Icon={HomeRoundedIcon}/>
                <MyNavLink text="Agent" path={`agent`} Icon={SmartToyRoundedIcon} />
                <MyNavLink text="Donnees" path={`donnees`} Icon={InsertLinkOutlinedIcon} />
                <MyNavLink text="Organisation" path={`organization`} Icon={BusinessRoundedIcon} />
                <MyNavLink text="Clients" path={`clients`} Icon={PersonSearchRoundedIcon} end={false}/>
            </div>


            <div className="flex flex-col gap-4">
                <MyNavLink text="Parametres" path="/parametres" Icon={SettingsOutlined} />
                <MyNavLink text="Quitter" path={'/workspace'} Icon={LogoutOutlined} />
            </div>
        </div>
    );
}

export default SideBar;