import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

function OfferDetails({ offer, isOpen, onClose }) {
    if (!offer) return null;

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullScreen
        >
            <DialogTitle className="border-b-2 border-b-gray-200">
                <div className="flex justify-between items-center">
                    <div className="px-3 py-2 bg-primary-bleu rounded text-white">
                        {offer.title}
                    </div>
                    <div>
                        <Button onClick={onClose}>Fermer</Button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="w-full bg-white my-2">
                    {/* <div className="bg-[#001751] text-white p-4">
                        <h2 className="text-2xl font-bold">{offer.title}</h2>
                    </div> */}
                    <div className="p-4">
                        <table className="w-full border-collapse">
                            <tbody>
                                <tbody>
                                    {Object.entries(offer)
                                        .filter(([key]) => key.toLowerCase() !== 'id')
                                        .map(([key, value]) => (
                                            <tr key={key} className="border-b border-gray-200">
                                                <td className="py-2 px-4 font-medium text-[#001751]">
                                                    {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                                                </td>
                                                <td className="py-2 px-4">
                                                    {key === 'company_profil_link' || key === 'job_link' ? (
                                                        <a href={value} target="_blank" rel="noopener noreferrer" className="text-[#FFBA13] hover:underline">
                                                            {value}
                                                        </a>
                                                    ) : key === 'candidate_profile' ? (
                                                        <ul className="list-disc pl-5">
                                                            {value.split('\n').map((item, index) => (
                                                                <li key={index} className="mb-1">{item.replace('* ', '')}</li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        value
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default OfferDetails;