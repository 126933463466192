import { io } from "socket.io-client"

const SOCKET_URL = process.env.REACT_APP_BACKEND_URL;

// Initialisation du socket
const socket = io(SOCKET_URL, {
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000
});

// Écoute les événements
socket.on("connect", () => {
  console.log("Socket Connected !!!");
});


export default socket;