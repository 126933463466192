import { useEffect, useState } from 'react';
import agentService from '../../services/agentService';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AgentCard from './AgentCard';
import { Button, CircularProgress } from '@mui/material';
import AgentDetail from './AgentDetail';

function Agents() {

    const [agentsData, setAgents] = useState([]);
    const [selected, setSelected] = useState(null);
    const [detailOpen, setDetailOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleStatusChange = (id, newStatus) => {
        setAgents((prevAgents) =>
            prevAgents.map((agent) =>
                agent.id === id ? { ...agent, status: newStatus } : agent
            )
        );
    };

    const handleSelect = (agent) => {
        setSelected(agent);
        setDetailOpen(true)
    }

    const handleCloseDetail = () => {
        setDetailOpen(false);
        setSelected(null);
    }

    const handleClickOpen = () => {
        setSelected(null);
        setDetailOpen(true);
    };


    const fetchAgents = async () => {
        try {
            setLoading(true);
            const response = await agentService.getAll();
            if (response.data) {
                setAgents(response.data);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAgents();
    }, []);

    return (
        <div className='p-4'>
            <div className='flex justify-between items-center'>
                <h1 className='m-2 text-primary-bleu text-2xl font-bold'>Liste des agents</h1>
                <div>
                    <Button onClick={handleClickOpen} variant='contained' color='secondary' startIcon={<AddCircleOutlineRoundedIcon />}>
                        Créer un agent
                    </Button>
                </div>
            </div>
            <div className='p-2 m-2'>
                {loading ? (
                    <div className='m-4 w-full flex justify-center'>
                        <CircularProgress color="secondary" />
                    </div>
                ) : agentsData.length === 0 ? (
                    <p className='text-xl text-secondary-raven'>Aucun élément à afficher.</p>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {agentsData.map((agent) => (
                            <AgentCard onClick={() => handleSelect(agent)} key={agent.id} agent={agent} onStatusChange={handleStatusChange} />
                        ))}
                    </div>
                )
                }
            </div>

            <AgentDetail isOpen={detailOpen} onClose={handleCloseDetail} agent={selected} onSubmit={fetchAgents} />
        </div>
    );
};

export default Agents;