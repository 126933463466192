import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded';
import scrapingService from '../../services/scrapingService';
import { useState } from 'react';
import { useSnackbar } from '../../context/SnackBarProvider';

function AgentCard({ agent, onStatusChange, onClick }) {
    const [status, setStatus] = useState(agent.status);
    const showAlert = useSnackbar();

    const toggleStatus = (event) => {
        event.stopPropagation();

        const newStatus = status === 'active' ? 'inactive' : 'active';
        setStatus(newStatus);
        const updatesAgent = {
            ...agent,
            status: newStatus
        }
        updateStatus(agent.id, updatesAgent);
    };

    const updateStatus = async (id, data) => {
        setStatus('active');
        onStatusChange(id, 'active');
        const site = agent.site;
        let scrapeRelust;
        try {
            const filter = {
                job_title: agent.keywords,
                location: agent.location
            }
            if (site.includes('linkedin')) {
                const scrapingData = {
                    email: agent.email,
                    password: agent.password,
                    keyword: agent.keywords,
                };
                console.log('scraping data:', scrapingData);

                scrapeRelust = await scrapingService.scrapeLinkedIn(scrapingData, agent.context);
            } else {
                scrapeRelust = await scrapingService.scrapeIndeed(filter);
            }
            if (scrapeRelust) {
                const timer = setTimeout(() => {
                    setStatus('inactive');
                    onStatusChange(id, 'inactive');
                    setStatus(false)
                }, 2000);
            }
        } catch (error) {
            showAlert('Erreur !', 'error')
            setStatus('inactive');
            onStatusChange(id, 'inactive');
        }
    };

    const switchContext = (context) => {
        switch (context.toLowerCase()) {
            case 'personna':
                return 'Contact'
            case 'company':
                return 'Organisation'
            default:
                return 'Offre'
        }
    };

    return (
        <div onClick={onClick} className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden hover:cursor-pointer hover:shadow-md">
            <div className="p-4 space-y-2">
                <div className='flex justify-between items-center'>
                    <h2 className="text-lg font-semibold text-primary-bleu">{agent.name}</h2>
                    <div>
                        <button onClick={(e) => toggleStatus(e)} className={`${status === 'active' ? 'text-red-500' : 'text-primary-bleu'} block`}>
                            {status === 'active' ?
                                (
                                    <div class="relative flex justify-center items-center text-red-500">
                                        <div className="absolute animate-spin rounded-full h-10 w-10 border-t-4 border-b-4 border-red-400"></div>
                                        <StopCircleRoundedIcon fontSize='large' />
                                    </div>
                                ) :
                                (
                                    <PlayCircleRoundedIcon fontSize='large' />
                                )}
                        </button>
                    </div>
                </div>

                <div className="flex items-center justify-between text-sm">
                    <span className="text-primary-bleu underline font-bold">{agent.site}</span>
                    <div className='flex items-center'>
                        <span className='text-primary-bleu'> {agent.security ? <LockIcon /> : <LockOpenIcon />}</span>
                    </div>
                </div>

                <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-500">Mots clés</span>
                    {/* <span className="text-primary-bleu">{agent.keywords}</span> */}
                    <span className="inline-block bg-primary-jaune text-primary-bleu px-2 py-1 rounded-full text-sm font-semibold text-nowrap">
                        {`${switchContext(agent.context)} : "${agent.keywords}"`}
                    </span>
                </div>
                <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-500">Localisation</span>
                    <span className="text-primary-bleu">{agent.location}</span>
                </div>
            </div>
        </div>
    );
};

export default AgentCard;