import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';

function CardPersonna({ contact, onClick, onEdit }) {

    const handleClickLink = (e) => {
        e.stopPropagation()
        onEdit()
    }

    return (
        <div key={contact.id} onClick={onClick} className='bg-white rounded-lg p-4 hover:shadow-lg transition-shadow overflow-hidden cursor-pointer border-2 border-gray-200'>
            <div className='flex justify-between mb-3'>
                <div>
                    <div className='text-xl font-bold text-primary-bleu'>{contact.name}</div>
                    <div className='text-md text-gray-400'>
                        {/* {contact.title.substring(0, 20)}... */}
                        {contact.title ? `${contact.title.substring(0, 20)}...` : 'No profile available'}

                    </div>
                </div>
                <div>
                    <button onClick={handleClickLink} className="text-gray-500 hover:text-primary-jaune">
                        <ManageAccountsRoundedIcon fontSize='large' />
                    </button>
                </div>
            </div>
            <div>
                <div className='flex items-center space-x-1 text-gray-600'>
                    <span><LocationOnRoundedIcon /></span>
                    <span>{contact.location}</span>
                </div>
            </div>
        </div>
    )
}
export default CardPersonna