import { Outlet, useParams } from "react-router-dom";
import SideBar from "../components/SiderBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import workspaceService from "../services/workspaceService";
import { setNotifications, setW } from "../redux/wSlice";
import TopBar from "../components/TopBar";
import notificationService from "../services/notificationService";

function Primary() {

    const { owner } = useSelector(state => state.w);

    const { workspaceId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchWorkspace = async () => {
            try {
                const response = await workspaceService.getById(workspaceId);
                if (response.data) {
                    dispatch(setW(response.data));
                }
            } catch (error) {

            }
        };

        fetchWorkspace();
    }, [dispatch]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                if(owner) {
                    const response = await notificationService.getByUser(owner.id);
                    if (response) {
                        const notif = response.data.notifications;
                        console.log('NOTIFICATION:', notif)
                        dispatch(setNotifications(notif))
                    }
                }
            } catch (error) {
                console.log(error)
            }
        };

        fetchNotifications();
    }, [owner, dispatch]);

    return (
        <div className="flex h-screen overflow-hidden">
            <SideBar />
            <div className="flex flex-col flex-grow overflow-hidden">
                <div className="sticky top-0 z-50">
                    <TopBar />
                </div>
                <div className="flex-grow overflow-auto">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Primary;