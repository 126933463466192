import apiClient from "../utils/apiClient";
import baseService from "./baseService";

const notificationService = {
    ...baseService('notifications'),

    getByUser: async (userId) => {
        return await apiClient.get(`notifications/list/${userId}`);
    },

    read: async (id) => {
        return await apiClient.post(`notifications/read/${id}`)
    }
};

export default notificationService;