import apiClient from "../utils/apiClient";

const scrapingService = {
    scrapeIndeed: async (filter) => {
        return await apiClient.post('/indeed', filter);
    },

    scrapeLinkedIn: async (data, context) => {
        switch (context) {
            case 'company':
                return await apiClient.post('/linkedin/company', data);
            case 'personna':
                return await apiClient.post('/linkedin/account', data);
            default:
                return await apiClient.post('/linkedin/scrape', data);
        }
    }
};

export default scrapingService;