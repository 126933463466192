import apiClient from "../utils/apiClient";
import baseService from "./baseService";

const offerService = {
    ...baseService('offers'),

    getPages: async (page, pageSize) => {
        return await apiClient.get(`/offers?page=${page}&page_size=${pageSize}`);
    },

    getFiltered: async (page, pageSize, filter) => {
        return await apiClient.get(`/offers?page=${page}&page_size=${pageSize}&search=${filter}`);
    },

    sendNews: async (data) => {
        return await apiClient.post('/offers/news', data);
    }
};

export default offerService;