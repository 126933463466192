import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "../../context/SnackBarProvider";
import companyService from "../../services/companyService";
import useConfirmDialog from "../../hook/useConfirm";

function FormOrganisation({ isOpen, onClose, onSubmit, company }) {
    const initialValues = {
        name: '',
        overview: '',
        website: '',
        phone_number: '',
        sector: '',
        size: '',
        head_office: '',
        founded_in: '',
        specializations: '',
    }

    const showAlert = useSnackbar();
    const { confirmDialog, ConfirmationDialog } = useConfirmDialog();

    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        if (company) {
            setFormValues({
                name: company.name || '',
                overview: company.overview || '',
                website: company.website || '',
                phone_number: company.phone_number || '',
                sector: company.sector || '',
                size: company.size || '',
                head_office: company.head_office || '',
                founded_in: company.founded_in || '',
                specializations: company.specializations || '',
            });
        } else {
            // Reset form values for creation mode
            setFormValues(initialValues);
        }
    }, [company]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleDelete = async () => {
        const confirm = await confirmDialog('Voulez-vous vraiment supprimer cet élément ?');
        if (confirm) {
            try {
                const response = await companyService.delete(company.id);
                if (response) {
                    onSubmit();
                    showAlert('Succès', 'info');
                    onClose();
                }
            } catch (error) {
                showAlert('Erreur !', 'error')
            }
        }
    };

    const handleSave = async () => {
        try {
            let response;
            if (company) {
                // Update existing company
                response = await companyService.update(company.id, formValues);
            } else {
                // Create new company
                response = await companyService.create(formValues);
            }
            if (response) {
                showAlert('Succès', 'success');
                onSubmit();
                onClose();
                setFormValues(initialValues)
            }
        } catch (error) {
            showAlert('Erreur !', 'error')
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle className='text-primary-bleu'>Detail d'une organisation</DialogTitle>

            <DialogContent>
                <form className="space-y-4 py-2">
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-name"
                            name="name"
                            label="Nom de l'entreprise"
                            value={formValues.name}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            required
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-overview"
                            name="overview"
                            label="Description"
                            value={formValues.overview}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            multiline
                            required
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex gap-2">
                        <TextField
                            id="outlined-website"
                            name="website"
                            label="Site Web"
                            value={formValues.website}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                        <TextField
                            id="outlined-phone"
                            name="phone_number"
                            label="Numéro de téléphone"
                            value={formValues.phone_number}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex gap-2">
                        <TextField
                            id="outlined-sector"
                            name="sector"
                            label="Secteur d'activité"
                            value={formValues.sector}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                        <TextField
                            id="outlined-size"
                            name="size"
                            label="Taille de l'entreprise"
                            value={formValues.size}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-head-office"
                            name="head_office"
                            label="Siège social"
                            value={formValues.head_office}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-founded-in"
                            name="founded_in"
                            label="Année de fondation"
                            value={formValues.founded_in}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-specializations"
                            name="specializations"
                            label="Spécialisations"
                            value={formValues.specializations}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            multiline
                            rows={4}
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                </form>
            </DialogContent>

            <DialogActions>
                <div className='flex justify-between gap-3'>
                    <div>
                        <Button onClick={onClose}>Fermer</Button>
                    </div>
                    {company && (
                        <div>
                            <Button onClick={handleDelete} variant='contained' color="error">Supprimer</Button>
                        </div>
                    )}
                    <div>
                        <Button onClick={handleSave} variant='contained'>Enregistrer</Button>
                    </div>
                </div>
                <ConfirmationDialog />
            </DialogActions>

        </Dialog>
    );
}

export default FormOrganisation;