import { useEffect, useState } from "react"
import { useSnackbar } from "../../context/SnackBarProvider";
import useConfirmDialog from "../../hook/useConfirm";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import personnaService from "../../services/personnaService";

const initialValues = {
    name: '',
    title: '',
    location: '',
    links: '',
    competence: '',
    resume: '',
}

function FormPersonna({ isOpen, onClose, onSubmit, person }) {

    const showAlert = useSnackbar();
    const { confirmDialog, ConfirmationDialog } = useConfirmDialog();

    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        if (person) {
            setFormValues({
                name: person.name || '',
                title: person.title || '',
                location: person.location || '',
                links: person.links || '',
                competence: person.competence || '',
                resume: person.resume || '',
            });
        } else {
            // Reset form values for creation mode
            setFormValues(initialValues);
        }
    }, [person]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleDelete = async () => {
        const confirm = await confirmDialog('Voulez-vous vraiment supprimer cet élément ?');
        if (confirm) {
            try {
                const response = await personnaService.delete(person.id);
                if (response) {
                    onSubmit();
                    showAlert('Succès', 'info');
                    onClose();
                }
            } catch (error) {
                showAlert('Erreur !', 'error');
            }
        }
    };

    const handleSave = async () => {
        try {
            let response;
            if (person) {
                // Update existing person
                response = await personnaService.update(person.id, formValues);
            } else {
                // Create new person
                // console.log(formValues);
                
                response = await personnaService.create(formValues);
            }
            if (response) {
                showAlert('Succès', 'success');
                onSubmit();
                onClose();
                setFormValues(initialValues);
            }
        } catch (error) {
            // console.log('error:', error)
            showAlert('Erreur !', 'error');
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle className='text-primary-bleu'>Détails d'un contact</DialogTitle>

            <DialogContent>
                <form className="space-y-4 py-2">
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-name"
                            name="name"
                            label="Nom"
                            value={formValues.name}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            required
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-title"
                            name="title"
                            label="Titre"
                            value={formValues.title}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-location"
                            name="location"
                            label="Emplacement"
                            value={formValues.location}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-links"
                            name="links"
                            label="Lien du profil (Linkedin)"
                            value={formValues.links}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-competence"
                            name="competence"
                            label="Compétence"
                            value={formValues.competence}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TextField
                            id="outlined-resume"
                            name="resume"
                            label="Résumé"
                            value={formValues.resume}
                            onChange={handleChange}
                            color="secondary"
                            fullWidth
                            multiline
                            rows={4}
                            className="p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                </form>
            </DialogContent>

            <DialogActions>
                <div className='flex justify-between gap-3'>
                    <div>
                        <Button onClick={onClose}>Fermer</Button>
                    </div>
                    {person && (
                        <div>
                            <Button onClick={handleDelete} variant='contained' color="error">Supprimer</Button>
                        </div>
                    )}
                    <div>
                        <Button onClick={handleSave} variant='contained'>Enregistrer</Button>
                    </div>
                </div>
                <ConfirmationDialog />
            </DialogActions>

        </Dialog>
    );
}

export default FormPersonna