import { useEffect, useState } from 'react';
import CardPersonna from './CardPersonna';
import DetailPersonna from './DetailPersonna';
import { Button, CircularProgress } from '@mui/material';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import FormPersonna from './FormPersonna';
import personnaService from '../../services/personnaService';

function ListPersona() {

    const [personnas, setPersonnas] = useState([])
    const [selected, setSelected] = useState(null)
    const [detailOpen, setDetailOpen] = useState(false)
    const [formOpen, setFormOpen] = useState(false)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPersonnas();
    }, [])

    const handleSelect = (personna) => {
        setSelected(personna)
        setDetailOpen(true)
    }

    const handleOpenEdit = (person) => {
        setSelected(person)
        setFormOpen(true)
    }

    const handleCloseDetail = () => {
        setSelected(null)
        setDetailOpen(false)
    }

    const handleOpenForm = () => {
        setFormOpen(true)
    }

    const handleCloseForm = () => {
        setFormOpen(false)
        setSelected(null)
    }

    const fetchPersonnas = async () => {
        try {
            setLoading(true);
            const response = await personnaService.getAll();
            if (response.data) {
                setPersonnas(response.data)
                setLoading(false);
            }
        } catch (error) {
            console.error(error)
            setLoading(false);
        }
    }

    return (
        <div className="p-2">
            <div className='flex justify-between items-center'>
                <h1 className='m-2 text-primary-bleu text-2xl font-bold'>Liste des contacts</h1>
                <div>
                    <Button onClick={handleOpenForm} variant='contained' color='secondary' startIcon={<PersonAddAltRoundedIcon />}>
                        Créer un contact
                    </Button>
                </div>
            </div>
            {loading ? (
                <div className='m-4 w-full flex justify-center'>
                    <CircularProgress color="secondary" />
                </div>
            ) : personnas.length === 0 ? (
                    <p className='text-xl text-secondary-raven m-4'>Aucun élément à afficher.</p>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 p-4">
                        {personnas.map((contact) => (
                            <CardPersonna
                                key={contact.id}
                                contact={contact}
                                onClick={() => handleSelect(contact)}
                                onEdit={() => handleOpenEdit(contact)}
                            />
                        ))}
                    </div>
                )
            }
            <DetailPersonna isOpen={detailOpen} personna={selected} onClose={handleCloseDetail} />
            <FormPersonna
                person={selected}
                isOpen={formOpen}
                onClose={handleCloseForm}
                onSubmit={fetchPersonnas}
            />

        </div>
    )
}

export default ListPersona